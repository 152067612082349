module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: "Fantasy Name Machine", // Navigation and Site Title
  siteTitleAlt: "FANTASYNAMEMACHINE.COM", // Alternative Site title for SEO
  siteTitleManifest: "fantasy-name-machine",
  siteUrl: "https://fantasynamemachine.com", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  siteHeadline: "Resources for GMs and Players of tabletop RPG's.", // Headline for schema.org JSONLD
  siteBanner: "/social/logo-icon.png", // Your image for og:image tag. You can find it in the /static folder
  favicon: "src/favicon.png", // Your image for favicons. You can find it in the /src folder
  siteDescription:
    "Fantasy Name Machine uses machine learning methods to generate all new fantasy character names. If you're looking for insiration for a new player or NPC name - come check us out.", // Your site description
  author: "@grvangotten", // Author for schemaORGJSONLD
  siteLogo: "/social/logo-icon.png", // Image for schemaORGJSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  // userTwitter: '@minimal', // Twitter Username - Optional
  // ogSiteName: 'minimal', // Facebook Site Name - Optional
  ogLanguage: "en_US", // Facebook Language
  googleAnalyticsID: "UA-135454281-3",

  // Manifest and Progress color
  // See: https://developers.google.com/web/fundamentals/web-app-manifest/
  themeColor: "#48A7E4",
  backgroundColor: "#2b2e3c"
};
